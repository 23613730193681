import {navigate} from '@reach/router';
import React from 'react';
import IzifillScanner from '../../components/app/IzifillScanner';
import StationInput from '../../components/app/StationInput';
import {setItemToStorage} from '../../helpers/localStorage';
import {
  STATION_CODE_KEY,
} from '../../helpers/constants';
import {navigateToOrder} from '../../helpers/navigation';

const Scanner = () => {
  const onGetStationCode = (stationCode) => {
    setItemToStorage(STATION_CODE_KEY, stationCode);
    navigateToOrder();
  };

  return (
    <div>
      <IzifillScanner onScanSuccess={onGetStationCode} />
      <StationInput onStationInput={onGetStationCode} />
    </div>
  );
};

export default Scanner;
