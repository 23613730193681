import { useLocation } from "@reach/router";
import loadable from '@loadable/component';
import React, { useEffect } from "react";
import scanframe from '../../images/app/scanframe.png';

const IzifillScanner = props => {
    const handleScan = urlData => {
        if (urlData) {
            props.onScanSuccess(parseToStationCode(urlData));
        }
    }
    const QrReader = loadable(() => import('react-qr-reader'));

    const handleError = err => {
        console.error(err)
    }
    const parseToStationCode = urlData => {
        //todo: do pattern check

        const params = new URLSearchParams((new URL(urlData)).searchParams);
        return params.get("stationcode");
    }

    const location = useLocation();
    
    useEffect(() => {
        const qs = parseToStationCode(location.href);
        if (qs !== "" && qs !== null) {
            console.log(qs);
            props.onScanSuccess(qs);
        }
    }, [])

    return (
        <div>
            <QrReader
                className="camera-scanner-container"
                showViewFinder={false}
                delay={300}
                onError={handleError}
                onScan={handleScan}
            />
            <div className="d-flex flex-column align-items-center camera-scanner-frame">
                <img className="mt-1" alt="Scan Frame" src={scanframe} />
                <h6 className="px-2 mt-1 ta-center camera-scanner-text">Scan QR code on the Water Station</h6>
            </div>
        </div>
    )
}
export default IzifillScanner