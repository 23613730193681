import React, {useRef, useState} from 'react';
import Button from '../../components/Button';
import scanbottle from '../../images/app/scanbottle.png';
import waterstation from '../../images/app/waterstation.png';

const StationInput = (props) => {
  const inputStation = useRef(null);
  const [isButtonEnable, setButtonEnable] = useState(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const stationCode = inputStation.current.value;

    if (stationCode) {
      props.onStationInput(stationCode);
    }
  };

  const updateButton = () => {
    inputStation.current.value = inputStation.current.value.toLowerCase();
    setButtonEnable(!!(inputStation.current.value));
  };

  return (
    <div className="px-2 bg-white my-1 d-flex flex-column station-input-container">
      <img className="align-self-center station-input-scan" alt="Scan Bottle" src={scanbottle} />
      <h6 className="mt-2 font-weight-bold station-input-title">Or just enter the station code</h6>
      <div className="p-1 d-flex station-input-text-container">
        <img className="h-100" alt="Water Station" src={waterstation} />

        <form className="d-flex w-100 pr-1" onSubmit={onFormSubmit}>
          <input ref={inputStation} className="w-100 station-input-text" onChange={updateButton} type="text" id="waterStationInput"
            placeholder="Enter the station code here"></input>
        </form>
        <Button
          onClick={onFormSubmit}
          className={`justify-content-center ${isButtonEnable ? 'd-flex' : 'd-none'}`}
          text="Enter" buttonClassName="button-secondary" />
      </div>
    </div>
  );
};
export default StationInput;
